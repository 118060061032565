import {apiInstance} from 'src/httpClient';
import {getToken, SERVER_URL, removeUserSession} from 'src/utils/common';

export const getUserList = async () => {
  try {
    const response = await apiInstance.get(`user/admin/getAll`, {
      headers: {
        Authorization: getToken(),
      },
    });
    return response.data.data;
  } catch (e) {
    console.log('error=======', e);
  }
};
