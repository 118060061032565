import {Icon} from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
import markCircleOutline from '@iconify/icons-eva/question-mark-circle-fill';
import lockOutFill from '@iconify/icons-eva/log-out-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import clipboardFill from '@iconify/icons-eva/clipboard-fill';
import flagFill from '@iconify/icons-eva/flag-fill';

// ----------------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: getIcon(peopleFill),
  },
  {
    title: 'ADU plan',
    path: '/dashboard/adu-plan',
    icon: getIcon(homeFill),
  },
  {
    title: 'Inquiry',
    path: '/dashboard/inquiry',
    icon: getIcon(markCircleOutline),
  },
  {
    title: 'Contact',
    path: '/dashboard/contact',
    icon: getIcon(clipboardFill),
  },
  {
    title: 'Add County',
    path: '/dashboard/county',
    icon: getIcon(flagFill),
  },

  {
    title: 'Logout',
    path: '/login',
    icon: getIcon(lockOutFill),
  },
];

export default sidebarConfig;
