import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@material-ui/core";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ type, searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      { searchQuery.length > 0 ? (
      <>
        <Typography gutterBottom align="center" variant="subtitle1">
          Not found
        </Typography>
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or
          using complete words.
        </Typography>
      </>
      ) : (
        <Typography gutterBottom align="center" variant="subtitle1">
          Not found any {type}
        </Typography>
      )}
    </Paper>
  );
}
