import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';

// material
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, {useState} from 'react';
import moment from 'moment';
import {getToken, SERVER_URL} from '../../utils/common';
import axios from 'axios';
import {makeStyles} from '@material-ui/styles';
import {LoadingButton} from '@material-ui/lab';
import {Icon} from '@iconify/react';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closesmall from '@iconify/icons-eva/close-fill';
import PropTypes from 'prop-types';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import './style.css';
import {apiInstance} from 'src/httpClient';

const CountrySelect = ({value, onChange, labels, ...rest}) => (
  <select
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}>
    <option value="">{labels['ZZ']}</option>
    {getCountries().map((country) => (
      <option key={country} value={country}>
        +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);
CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function AddUserForm(props) {
  const {templates, onClick, isEdit} = props;
  const data = props.data._id ? props.data : '';
  const [country, setCountry] = useState(data.phone?.code || 'US');

  templates.sort(function (a, b) {
    return (
      new Date(b.dose[0].first_Dose_date) - new Date(a.dose[0].first_Dose_date)
    );
  });

  const [modalStyle] = useState(getModalStyle);
  const [state, setState] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [temp, setTemp] = useState();
  const [date, setDate] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [fullname, setFullname] = useState('');
  const [passport, setPassport] = useState('');
  const [phone, setPhone] = useState('');

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    setState(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleFullname = (e) => {
    setFullname(e.target.value);
  };

  const handlecountry = async (val) => {
    setCountry(val);
    // if (data) {
    //   setCountry(data.phone.countryCode);
    //   console.log('countryCode==========', data.phone.countryCode);
    // } else {
    //   setCountry(val);
    //   console.log('-------else-----------');
    // }
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 1000,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: 5,
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();
  // const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
    phone: Yup.string().required('phone is required'),
    name: Yup.string().required('name is required'),
    // .matches(phoneRegExp, 'Phone number is not valid'),
  });

  const handleCreateUser = async (values) => {
    const userData = {
      email: `${values.email}`,
      password: `${values.password}`,
      name: `${values.name}`,
      countryCode: `+${getCountryCallingCode(country)}`,
      code: country,
      phoneNumber: values.phone,
    };
    if (data) {
      try {
        const res = await apiInstance.put(
          `user/admin/update/${data._id}`,
          userData,
          {
            headers: {
              Authorization: getToken(),
            },
          }
        );
        onClick();
        isSubmitting = false;
      } catch (e) {
        isSubmitting = false;
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('something went wrong please try again.');
        }
      }
    } else {
      try {
        const res = await apiInstance.post('user', userData);

        onClick();
        isSubmitting = false;
      } catch (e) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('something went wrong please try again.');
        }
        isSubmitting = false;
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      name: data != '' ? data?.name : '',
      email: data ? data.email : '',
      phone: data != '' ? data.phone.phoneNumber : '',
      password: data != '' ? data.email : '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => handleCreateUser(values),
  });
  const {errors, touched, values, handleSubmit, getFieldProps, isSubmitting} =
    formik;

  return (
    <>
      <div style={modalStyle} className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
          className={classes.addusertitle}>
          <h2 id="simple-modal-title">Add User</h2>

          <Icon
            icon={closesmall}
            style={{color: 'rgb(151 151 151)', fontSize: '27px'}}
            onClick={onClick}
          />
        </Grid>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  label="full name"
                  disabled={!isEdit ? true : false}
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  // autoComplete="username"
                  type="email"
                  disabled={!isEdit || data ? true : false}
                  label="Email address"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>

              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <div className="country-phone" style={{width: '49%'}}>
                  <CountrySelect
                    labels={en}
                    value={country}
                    className="codeselect"
                    // value={data ? data.phone?.code : country}
                    // value={country}
                    disabled={!isEdit ? true : false}
                    // onChange={(val) => setCountry(val)}
                    onChange={(val) => handlecountry(val)}
                  />
                  <TextField
                    type="number"
                    placeholder="enter phone"
                    disabled={!isEdit ? true : false}
                    className=" phoneinput"
                    id="phone"
                    name="phone"
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </div>

                {/* <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  disabled={!isEdit ? true : false}
                  label="Password"
                  value={password}
                  onChange={handlePassword}
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                /> */}
              </Stack>

              {errorMessage && (
                <Typography
                  variant="p"
                  gutterBottom
                  style={{color: '#F00', display: 'block'}}>
                  {errorMessage}
                </Typography>
              )}

              {isEdit ? (
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}>
                  {data != '' ? 'Update' : 'Register'}
                </LoadingButton>
              ) : undefined}
            </Stack>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
}
