/* eslint-disable no-unused-vars */
import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import NotFound from './pages/Page404';
import AduPlan from './pages/AduPlan';
import Inquiry from './pages/Inquiry';
import Contact from './pages/Contact';
import County from './pages/County';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard/',
      element: <DashboardLayout />,
      children: [
        {path: '/', element: <Navigate to="/dashboard/user" replace />},
        // { path: "app", element: <DashboardApp /> },
        {path: 'user', element: <User />},

        {path: 'adu-plan', element: <AduPlan />},
        {path: 'inquiry', element: <Inquiry />},
        {path: 'contact', element: <Contact />},
        {path: 'county', element: <County />},
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {path: 'login', element: <Login />},
        {path: '404', element: <NotFound />},
        {path: '/', element: <Navigate to="/dashboard/user" />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },

    {path: '*', element: <Navigate to="/404" replace />},
  ]);
}
