import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';

// material
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {getToken, SERVER_URL} from '../../utils/common';
import axios from 'axios';
import {makeStyles} from '@material-ui/styles';
import {LoadingButton} from '@material-ui/lab';
import {Icon} from '@iconify/react';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closesmall from '@iconify/icons-eva/close-fill';
import './style.css';
import Dropzone from 'react-dropzone';
import {apiInstance} from 'src/httpClient';
import {setToken} from 'src/httpClient/ClientHelper';
import {Country, State, City} from 'country-state-city';
import closeFill from '@iconify/icons-eva/close-circle-outline';

const dropzoneStyle = {
  width: '100%',
  height: 'auto',
  borderWidth: 2,
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: 5,
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function AddAduPlan(props) {
  const {data, onClick, isEdit, isClone} = props;

  const [modalStyle] = useState(getModalStyle);
  const [errorMessage, setErrorMessage] = useState();
  const [file, setFile] = useState([]);
  const [images, setImages] = useState([]);
  const [isoCode, setIsoCode] = useState();
  const [county, setcounty] = useState([]);
  const [cityVal, setCityVal] = useState();
  const [existingImg, setExistingImg] = useState([data.image]);

  const handleStateSelect = async (e) => {
    console.log('value====', e.isoCode);

    setIsoCode(e.isoCode);
    try {
      const {data} = await apiInstance.get(
        `county/admin/getByIsoCode?isoCode=${e.isoCode}`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      setcounty(data.data);
    } catch (e) {
      console.log('errrr--', e.response);
    }
  };

  const stateval = State.getStatesOfCountry('US');

  const cityval = City.getCitiesOfState('US', isoCode);

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 1000,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: 5,
      padding: theme.spacing(2, 4, 3),
      overflow: 'auto',
      maxHeight: '700px',
    },
    dropzoneStyle: {
      width: '100%',
      height: 'auto',
      borderWidth: 2,
      borderColor: 'rgb(102, 102, 102)',
      borderStyle: 'dashed',
      borderRadius: 5,
    },
  }));
  const classes = useStyles();
  const RegisterSchema = Yup.object().shape({
    planname: Yup.string().required('planname is required'),

    bedroom: Yup.string().required('bedroom is required'),
    footprint: Yup.string().required('footprint is required'),
    approval: Yup.string().required('approval is required'),
    description: Yup.string().required('description is required'),
    city: Yup.string().required('city is required'),
    state: Yup.string().required('state is required'),
  });

  const uploadMultipleFiles = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setFile([...file, ...fileArray]);
    setImages([...images, ...fileObj[0]]);
  };

  const deleteFile = (i) => {
    console.log('i: ', i);
    let arr = images;
    const s = file.filter((url, index) => index !== i);
    setFile(s);
    if (arr.length > 0) {
      const item = arr[i];
      const index = Array.from(arr).indexOf(item);
      if (index > -1) {
        arr.splice(index, 1);
        setImages(arr);
      }
    }
  };

  const deleteEditFile = (i) => {
    console.log('i: ', i);
    const newArr = data.image;
    if (newArr.length > 0) {
      const item = newArr[i];
      const index = Array.from(newArr).indexOf(item);
      if (index > -1) {
        newArr.splice(index, 1);
        setExistingImg(newArr);
      }
    }
  };

  const handleCreateADU = async (values) => {
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    formData.append('adu_name', values.planname);

    if (data.price && !values.price) {
      formData.append('price', 0);
    }
    if (values.price) {
      formData.append('price', values.price);
    }

    formData.append('bedRooms', values.bedroom);
    formData.append('footprint', values.footprint);
    formData.append('address', values.address);
    formData.append('approvals', values.approval);

    if (data.lotSize && !values.size) {
      formData.append('lotSize', 0);
    }
    if (values.size) {
      formData.append('lotSize', values.size);
    }

    formData.append('description', values.description);
    formData.append('name', values.state);
    formData.append('county', values.county);
    formData.append('iso', isoCode);
    formData.append('city', values.city);
    formData.append('zipCode', values.zipcode);
    formData.append('existingimage', existingImg);

    if (data && isEdit) {
      try {
        const response = await apiInstance.put(
          `adu/admin/update/${data._id}`,
          formData,
          {
            headers: {
              Authorization: getToken(),
            },
          }
        );
        console.log('update response: ', response);

        onClick();
        isSubmitting = false;
      } catch (e) {
        console.log('Error=========', e.response);
        isSubmitting = false;
      }
    }
    if (data && isClone) {
      try {
        const response = await apiInstance.post(`adu/admin/create`, formData, {
          headers: {
            Authorization: getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        onClick();
        isSubmitting = false;
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('Adu not created');
        }
        isSubmitting = false;
      }
    } else {
      try {
        const response = await apiInstance.post(`adu/admin/create`, formData, {
          headers: {
            Authorization: getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        onClick();
        isSubmitting = false;
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('Adu not created');
        }
        isSubmitting = false;
      }
    }
  };

  const handleCounty = () => {
    alert('Hii');
  };
  const formik = useFormik({
    initialValues: {
      planname: data ? data.adu_name : '',
      size: data ? data.lotSize : '',
      price: data ? data.price : '',
      bedroom: data ? data.bedRooms : '',
      footprint: data ? data.footprint : '',
      approval: data ? data.approvals : '',
      address: data ? data.address : '',
      description: data ? data.description : '',
      state: data ? data.state.name : '',
      city: data ? data.city : '',
      county: data ? data.county : '',
      zipcode: data ? data.zipCode : '',
      // file: [],
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => handleCreateADU(values),
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    getFieldProps,
    isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <>
      <div style={modalStyle} className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
          className={classes.addusertitle}>
          <h2 id="simple-modal-title">Add AduPlan</h2>

          <Icon
            icon={closesmall}
            style={{color: 'rgb(151 151 151)', fontSize: '27px'}}
            onClick={onClick}
          />
        </Grid>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="planname"
                  type="text"
                  disabled={!isEdit && !isClone ? true : false}
                  label="Plan name"
                  {...getFieldProps('planname')}
                  error={Boolean(touched.planname && errors.planname)}
                  helperText={touched.planname && errors.planname}
                />

                <TextField
                  fullWidth
                  autoComplete="price"
                  type="number"
                  disabled={!isEdit && !isClone ? true : false}
                  label="Price"
                  {...getFieldProps('price')}
                  error={Boolean(touched.price && errors.price)}
                  // helperText={touched.price && errors.price}
                />
              </Stack>

              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  type="number"
                  fullWidth
                  label="Lot Size"
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('size')}
                  error={Boolean(touched.size && errors.size)}
                  helperText={touched.size && errors.size}
                />
                <TextField
                  select
                  label="Bedroom"
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  onChange={handleChange}
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('bedroom')}
                  error={Boolean(touched.bedroom && errors.bedroom)}
                  helperText={touched.bedroom && errors.bedroom}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="0BHK">0BHK</MenuItem>
                  <MenuItem value="1BHK">1BHK</MenuItem>
                  <MenuItem value="2BHK">2BHK</MenuItem>
                  <MenuItem value="3BHK">3BHK</MenuItem>
                  <MenuItem value="4BHK">4BHK</MenuItem>
                  <MenuItem value="5BHK">5BHK</MenuItem>
                  <MenuItem value="6BHK">6BHK</MenuItem>
                  {/* </Select> */}
                </TextField>
                {/* <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}>
                  
                  
                </FormControl> */}
              </Stack>

              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  label="Footprint"
                  // onChange={handlePassport}
                  // value={passport}
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('footprint')}
                  error={Boolean(touched.footprint && errors.footprint)}
                  helperText={touched.footprint && errors.footprint}
                />

                <TextField
                  fullWidth
                  label="Address"
                  // onChange={handlePassport}
                  // value={passport}
                  {...getFieldProps('address')}
                  disabled={!isEdit && !isClone ? true : false}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Stack>

              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  select={stateval.length > 0 ? true : false}
                  label="State"
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('state')}
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}>
                  {stateval.length > 0 &&
                    stateval.map((ele) => {
                      return (
                        <MenuItem
                          onClick={() => handleStateSelect(ele)}
                          value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                </TextField>

                <TextField
                  select={county.length > 0 ? true : false}
                  label="County"
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('county')}
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}>
                  {county.length > 0 &&
                    county.map((ele) => {
                      return (
                        <MenuItem
                          onClick={() => handleStateSelect(ele)}
                          value={ele.county}>
                          {ele.county}
                        </MenuItem>
                      );
                    })}
                </TextField>

                <TextField
                  select={cityval.length > 0 ? true : false}
                  label="City"
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  // onChange={handleChange}
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('city')}
                  // value={cityVal}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}>
                  {cityval.length > 0 &&
                    cityval.map((ele) => {
                      return (
                        <MenuItem
                          // onClick={() => handleCitySelect(ele)}
                          value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Stack>

              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  label="Zipcode"
                  // onChange={handlePassport}
                  // value={passport}
                  {...getFieldProps('zipcode')}
                  disabled={!isEdit && !isClone ? true : false}
                  error={Boolean(touched.zipcode && errors.zipcode)}
                  helperText={touched.zipcode && errors.zipcode}
                />
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  label="Select Approval"
                  onChange={handleChange}
                  disabled={!isEdit && !isClone ? true : false}
                  {...getFieldProps('approval')}
                  error={Boolean(touched.approval && errors.approval)}
                  helperText={touched.approval && errors.approval}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="City pre-approved">
                    City pre approved
                  </MenuItem>
                  <MenuItem value="ACM pre-approved">ACM pre-approved</MenuItem>
                  <MenuItem value="Unapproved">Unapproved</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                </TextField>

                {/* <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  >


                  <InputLabel>Select Approval</InputLabel> */}
                {/* <Select
                    // value={state}
                    label="Select State"
                    onChange={handleChange}
                    disabled={!isEdit ? true : false}
                    {...getFieldProps('approval')}
                    error={Boolean(touched.approval && errors.approval)}
                    helperText={touched.approval && errors.approval}>
                    
                  </Select>
                </FormControl> */}
              </Stack>

              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  label="Description"
                  disabled={!isEdit && !isClone ? true : false}
                  // onChange={handlePassport}
                  // value={passport}
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Stack>

              <Stack>
                <div className="form-group file-input">
                  <input
                    id="file"
                    name="file"
                    type="file"
                    className="form-control"
                    onChange={uploadMultipleFiles}
                    disabled={!isEdit && !isClone ? true : false}
                    // {...getFieldProps('file')}
                    // error={Boolean(touched.file && errors.file)}
                    // helperText={touched.file && errors.file}
                    multiple
                  />
                  <label for="file">upload image</label>
                </div>
                {errorMessage}

                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {file && file.length > 0 ? (
                    <div
                      className="form-group preview"
                      style={{
                        width: 'auto',
                        // height: '200px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '20px',
                      }}>
                      {(file || []).map((url, index) => {
                        return (
                          <div
                            key={url}
                            style={{
                              width: '200px',
                              height: '170px',
                              position: 'relative',
                              marginRight: '10px',
                              marginBottom: '20px',
                            }}>
                            <img
                              src={url}
                              alt=""
                              style={{height: '100%', width: '100%'}}
                            />
                            {/* <button
                              type="button"
                              style={{position: 'absolute'}}
                              onClick={() => deleteFile(index)}>
                              delete
                            </button> */}
                            <i
                              className="far fa-times-circle closeicon"
                              onClick={() => deleteFile(index)}></i>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}

                  {data && data.image && data.image.length > 0 ? (
                    <div
                      className="form-group preview"
                      style={{
                        width: 'auto',
                        // height: '170px',
                        display: 'flex',
                        marginTop: '20px',
                      }}>
                      {data?.image.map((url, index) => {
                        return (
                          <>
                            <div
                              style={{
                                width: '200px',
                                height: '170px',
                                position: 'relative',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}>
                              <img
                                src={url}
                                alt="img"
                                style={{height: '100%', width: '100%'}}
                              />

                              <i
                                className="far fa-times-circle closeicon"
                                onClick={() => deleteEditFile(index)}></i>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </Stack>

              {errorMessage && (
                <Typography
                  variant="p"
                  gutterBottom
                  style={{color: '#F00', display: 'block'}}>
                  {errorMessage}
                </Typography>
              )}

              {isEdit ? (
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}>
                  {data ? 'Update' : 'Add'}
                </LoadingButton>
              ) : undefined}

              {isClone ? (
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}>
                  Save
                </LoadingButton>
              ) : undefined}
            </Stack>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
}
