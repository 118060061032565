import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';

// material
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {getToken, SERVER_URL} from '../../utils/common';
import axios from 'axios';
import {makeStyles} from '@material-ui/styles';
import {LoadingButton} from '@material-ui/lab';
import {Icon} from '@iconify/react';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closesmall from '@iconify/icons-eva/close-fill';
import './style.css';
import Dropzone from 'react-dropzone';
import {apiInstance} from 'src/httpClient';
import {setToken} from 'src/httpClient/ClientHelper';
import {Country, State, City} from 'country-state-city';

const dropzoneStyle = {
  width: '100%',
  height: 'auto',
  borderWidth: 2,
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: 5,
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function AddCounty(props) {
  const {data, onClick, isEdit} = props;
  console.log('data: ', data);

  const [modalStyle] = useState(getModalStyle);
  const [errorMessage, setErrorMessage] = useState();

  // useEffect(() => {
  //   const cityval = City.getCitiesOfState('US', isoCode);
  //   console.log('cityval: ', cityval);
  // }, []);

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 1000,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: 5,
      padding: theme.spacing(2, 4, 3),
      overflow: 'auto',
      maxHeight: '700px',
    },
    dropzoneStyle: {
      width: '100%',
      height: 'auto',
      borderWidth: 2,
      borderColor: 'rgb(102, 102, 102)',
      borderStyle: 'dashed',
      borderRadius: 5,
    },
  }));
  const classes = useStyles();
  // const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const RegisterSchema = Yup.object().shape({
    isocode: Yup.string().required('isocode is required'),
    county: Yup.string().required('county is required'),
  });

  const handleCounty = async (values) => {
    console.log('valuess---------', values);
    const countyData = {
      county: values.county,
      isoCode: values.isocode,
    };
    console.log('countyData: ', countyData);
    if (data) {
      try {
        const response = await apiInstance.put(
          `county/admin/update/${data._id}`,
          countyData,
          {
            headers: {
              Authorization: getToken(),
            },
          }
        );
        console.log('update res==========', response);
        onClick();
        isSubmitting = false;
      } catch (e) {
        isSubmitting = false;
        console.log('Error=========', e.response);
      }
    } else {
      try {
        const response = await apiInstance.post(
          `county/admin/create`,
          countyData,
          {
            headers: {
              Authorization: getToken(),
            },
          }
        );
        console.log('countyData createe===========', response);
        onClick();
        isSubmitting = false;
      } catch (e) {
        console.log('eeee', e.response);
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('County not created');
        }
        isSubmitting = false;
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      isocode: data ? data.isoCode : '',
      county: data ? data.county : '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => handleCounty(values),
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    getFieldProps,
    isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <>
      <div style={modalStyle} className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
          className={classes.addusertitle}>
          <h2 id="simple-modal-title">Add AduPlan</h2>

          <Icon
            icon={closesmall}
            style={{color: 'rgb(151 151 151)', fontSize: '27px'}}
            onClick={onClick}
          />
        </Grid>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="isocode"
                  type="text"
                  disabled={!isEdit ? true : false}
                  label="Iso code"
                  {...getFieldProps('isocode')}
                  error={Boolean(touched.isocode && errors.isocode)}
                  helperText={touched.isocode && errors.isocode}
                />

                <TextField
                  fullWidth
                  autoComplete="county"
                  type="text"
                  disabled={!isEdit ? true : false}
                  label="County"
                  {...getFieldProps('county')}
                  error={Boolean(touched.county && errors.county)}
                  helperText={touched.county && errors.county}
                />
              </Stack>

              {errorMessage && (
                <Typography
                  variant="p"
                  gutterBottom
                  style={{color: '#F00', display: 'block'}}>
                  {errorMessage}
                </Typography>
              )}

              {isEdit ? (
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}>
                  {data ? 'Update' : 'Add'}
                </LoadingButton>
              ) : undefined}
            </Stack>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
}
