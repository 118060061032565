import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@material-ui/core/styles';
import {Card, Stack, Link, Container, Typography} from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {MHidden} from '../components/@material-extend';
import {LoginForm} from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Adu-Admin">
      {/* <AuthLayout /> */}

      <MHidden width="mdDown">
        <SectionStyle
          style={{
            maxWidth: '750px',
            position: 'relative',
            margin: '0',
            borderRadius: '0px',
          }}>
          <img
            src="/static/logo-white.png"
            style={{
              position: 'absolute',
              color: '#fff',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              width: '150px',
            }}
          />
          <img
            src="/static/images/house.png"
            alt="login"
            style={{height: '100%'}}
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{mb: 5}}>
            <Typography variant="h4" gutterBottom>
              Welcome to ADU
            </Typography>
            {/* <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography> */}
          </Stack>

          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
