import {Icon} from '@iconify/react';
import {useRef, useState} from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import {Link as RouterLink} from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import copyFill from '@iconify/icons-eva/copy-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import {getToken, SERVER_URL} from '../../../utils/common';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import {apiInstance} from 'src/httpClient';

const CountrySelect = ({value, onChange, labels, ...rest}) => (
  <select
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}>
    <option value="">{labels['ZZ']}</option>
    {getCountries().map((country) => (
      <option key={country} value={country}>
        +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);
CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const {openModel, data, isgetData, type, isEdit} = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [country, setCountry] = useState(data.phone?.code || 'US');

  const handleClickOpen = () => {
    (type !== 'user' && type === 'template') || type === 'location'
      ? setOpen(true)
      : hendleBan();
  };

  const hanleDelete = () => {
    setIsdelete(true);
    type === 'adu' || type === 'contact' || type === 'county'
      ? setOpen(true)
      : null;
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpen();
  };

  const hendleBan = async () => {
    switch (type) {
      case 'user':
        if (data?.isDeleted === false) {
          try {
            const res = await apiInstance.delete(
              `user/admin/remove/${data._id}`,
              {
                headers: {
                  Authorization: getToken(),
                },
              }
            );
            setIsOpen();
            isgetData();
          } catch (e) {
            console.log(
              'Something went wrong while banning user. Please try again later.'
            );
          }
        } else {
          const userData = {
            name: `${data.name}`,
            countryCode: +getCountryCallingCode(country),
            code: country,
            phoneNumber: data.phone.phoneNumber,
            isDeleted: false,
          };
          try {
            const res = await apiInstance.put(`user/${data._id}`, userData, {
              headers: {
                Authorization: getToken(),
              },
            });
            console.log('resres======', res);
            setIsOpen();
            isgetData();
          } catch (error) {
            if (error.response.status === 404) {
              setError(error.response.data.message);
            } else if (error.response.status === 400) {
              setError(error.response.data.message);
            } else {
              setError(
                'Something went wrong while banning user. Please try again later.'
              );
            }
            isSubmitting = false;
          }
        }

        break;
      case 'adu':
        if (isdelete) {
          console.log('isdelete: ', isdelete);

          try {
            console.log('isdelete: =============');

            const response = await apiInstance.delete(
              `adu/admin/remove/${data?._id}`,
              {
                headers: {
                  Authorization: getToken(),
                },
              }
            );
            console.log('response-----', response);

            setIsOpen();
            isgetData();
          } catch (error) {
            console.log('Error------', error.response);
            if (error.response.status === 404) {
              setError(error.response.data.message);
            } else if (error.response.status === 400) {
              setError(error.response.data.message);
            } else {
              setError('profile not updated');
            }
          }
          setOpen(false);
        }
        break;
      case 'contact':
        if (isdelete) {
          console.log('contact-------------isdelete: ', isdelete);

          try {
            console.log('isdelete: =============');

            const response = await apiInstance.delete(
              `contact/admin/remove/${data?._id}`,
              {
                headers: {
                  Authorization: getToken(),
                },
              }
            );
            console.log('response-----', response);

            setIsOpen();
            isgetData();
          } catch (error) {
            console.log('Error------', error.response);
            if (error.response.status === 404) {
              setError(error.response.data.message);
            } else if (error.response.status === 400) {
              setError(error.response.data.message);
            } else {
              setError('Something went wrong.please try again..');
            }
          }
          setOpen(false);
        }
        break;
      case 'county':
        console.log('type---', props.type);
        if (isdelete) {
          console.log('isdelete: ', isdelete);

          try {
            console.log('isdelete: =============');

            const response = await apiInstance.delete(
              `county/admin/remove/${data?._id}`,
              {
                headers: {
                  Authorization: getToken(),
                },
              }
            );
            console.log('response-----', response);

            setIsOpen();
            isgetData();
          } catch (error) {
            console.log('Error------', error.response);
            if (error.response.status === 404) {
              setError(error.response.data.message);
            } else if (error.response.status === 400) {
              setError(error.response.data.message);
            } else {
              setError('Something went wrong.please try again.');
            }
          }
          setOpen(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isdelete
              ? ' Are you sure you want to delete?'
              : 'Are you sure you want to delete this template?'}
            {/* Are you sure you want to delete this template? */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="error" onClick={hendleBan} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {width: 200, maxWidth: '100%'},
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        {type === 'user' || type === 'adu' || type === 'county' ? (
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{color: 'text.secondary'}}
            onClick={() => {
              setIsOpen(false);
              openModel(data, true, false);
            }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{variant: 'body2'}}
            />
          </MenuItem>
        ) : null}

        {/* hendleBan */}
        {type === 'user' ? (
          <MenuItem sx={{color: 'text.secondary'}} onClick={handleClickOpen}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={
                type === 'user'
                  ? data?.isDeleted
                    ? 'unBan User'
                    : 'Ban User'
                  : 'delete'
              }
              primaryTypographyProps={{variant: 'body2'}}
            />
          </MenuItem>
        ) : null}

        {type === 'user' || type === 'adu' ? (
          <MenuItem
            sx={{color: 'text.secondary'}}
            onClick={() => {
              setIsOpen(false);
              openModel(data, false, false);
            }}
            // onClick={hanleDelete}
          >
            <ListItemIcon>
              <Icon icon={eyeFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              primaryTypographyProps={{variant: 'body2'}}
              // isdelete="deleteuser"
            />
          </MenuItem>
        ) : null}

        {type === 'adu' ? (
          <MenuItem
            sx={{color: 'text.secondary'}}
            onClick={() => {
              setIsOpen(false);
              openModel(data, false, true);
            }}>
            <ListItemIcon>
              <Icon icon={copyFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Clone"
              primaryTypographyProps={{variant: 'body2'}}
              // isdelete="deleteuser"
            />
          </MenuItem>
        ) : null}

        {type === 'adu' || type === 'contact' || type === 'county' ? (
          <MenuItem sx={{color: 'text.secondary'}} onClick={hanleDelete}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{variant: 'body2'}}
              isdelete="deleteuser"
            />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
