// material
import {
  Button,
  Card,
  Container,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from '../components/_dashboard/user';
// session data
import {getToken, removeUserSession} from 'src/utils/common';
import axios from 'axios';
import AddUserForm from '../components/Forms/AddUser';
import {Icon} from '@iconify/react';
import Label from '../components/Label';
// components
import Page from '../components/Page';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import moment from 'moment';
import {filter} from 'lodash';
import plusFill from '@iconify/icons-eva/plus-fill';
import {getUserList} from '../actions/user';

// -----------------------------------Z-----------------------------------

const TABLE_HEAD = [
  {id: 'name', label: 'Full name', alignRight: false},
  {id: 'email', label: 'Email', alignRight: false},
  {id: 'phone', label: 'Phone', alignRight: false},
  {id: 'status', label: 'Status', alignRight: false},
  {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [filterName, setFilterName] = useState('');
  const [userList, setUserList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [loding, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (getToken() == null) {
      removeUserSession();
      navigate('/login', {replace: true});
    } else {
      setDatatoList();
    }
  }, []);

  const setDatatoList = async () => {
    setLoading(true);
    const res = await getUserList();
    setUserList(res);
    setLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const [open, setOpen] = useState(false);

  const handleOpen = (val, isEdit) => {
    setIsEdit(isEdit);
    if (val) {
      setData(val);
    } else {
      setData(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDatatoList();
  };

  return (
    <Page title="User | Adu-admin">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={() => handleOpen(null, true)}
            startIcon={<Icon icon={plusFill} />}>
            New User
          </Button> */}
        </Stack>

        <Modal
          open={open}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}>
          <AddUserForm
            onClick={handleClose}
            data={data ? data : ''}
            templates={templates}
            isEdit={isEdit}
          />
        </Modal>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            type="user"
          />

          <Scrollbar>
            <TableContainer sx={{minWidth: 800}}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loding ? (
                  <>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              width: '100%',
                              // height: '500px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <CircularProgress
                              size={50}
                              color="inherit"
                              style={{marginLeft: '10px'}}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <>
                    {!isUserNotFound ? (
                      <TableBody>
                        {filteredUsers
                          .reverse()
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {_id, name, email, isDeleted} = row;
                            const isItemSelected =
                              selected.indexOf(email) !== -1;
                            const phone = `${row.phone?.countryCode} ${row.phone?.phoneNumber}`;

                            return (
                              <TableRow
                                hover
                                key={_id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none">
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Typography
                                      variant="subtitle2"
                                      style={{padding: '16px'}}
                                      noWrap>
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left">{phone}</TableCell>

                                {/* <TableCell align="left">
                            {fullname?.length > 0 ? 'Yes' : 'No'}
                          </TableCell> */}
                                <TableCell align="left">
                                  <Label
                                    variant="ghost"
                                    color={(isDeleted && 'error') || 'success'}>
                                    {isDeleted ? 'banned' : 'active'}
                                  </Label>
                                </TableCell>

                                <TableCell align="right">
                                  <UserMoreMenu
                                    data={row}
                                    isgetData={setDatatoList}
                                    type="user"
                                    openModel={(val, isEdit) =>
                                      handleOpen(row, isEdit)
                                    }
                                    isEdit={isEdit}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{py: 3}}>
                            <SearchNotFound
                              searchQuery={filterName}
                              type="user"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
